<template>
  <b-overlay variant="white" :show="showLoading" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
    <b-card ref="bCard" v-bind="cardAttrs" no-body :aria-controls="parentID" :style="cardStyles" v-on="$listeners">
      <div v-if="showHeader" class="card-header">
        <div>
          <b-card-title>
            <div class="d-flex align-items-center">
              <feather-icon v-if="$attrs.icon" :icon="$attrs.icon" size="22" />
              <h4 class="mb-0">
                {{ $attrs.title }}
              </h4>
            </div>
          </b-card-title>
          <b-card-sub-title v-if="$attrs['sub-title']">
            {{ $attrs['sub-title'] }}
          </b-card-sub-title>
        </div>
      </div>
      <slot v-if="$attrs['no-body'] !== undefined" />
      <b-card-body class="pt-1" v-else>
        <slot name="body" />
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    noActions: {
      type: Boolean,
      default: false,
    },
    actionRefresh: {
      type: Boolean,
      default: false,
    },
    actionNewAdd: {
      type: Boolean,
      default: false,
    },
    showLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      parentID: '',
      cardClosed: false,
      cardStyles: {},
    };
  },
  computed: {
    cardAttrs() {
      const cardAttrs = JSON.parse(JSON.stringify(this.$attrs));
      delete cardAttrs.title;
      delete cardAttrs['sub-title'];
      return cardAttrs;
    },
    showHeader() {
      return this.$attrs.title || this.$attrs['sub-title'] || this.noActions;
    },
    showActions() {
      if (this.noActions) return false;
      return true;
    },
  },
  created() {
    this.parentID = String(Math.floor(Math.random() * 10) + 1);
  },
  methods: {
    triggerRefresh() {
      this.$emit('refresh', true);
    },
    triggerNewAdd(event) {
      this.$emit('newAdd', true);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card {
  border-radius: 0rem !important;
  .card-header {
    border-bottom: 1px solid rgb(241, 241, 241) !important;
    padding: 1rem;
    .card-title {
      h4 {
        margin-left: 4px;
      }
    }
    .card-subtitle {
      margin-top: 2px;
      font-weight: 200;
      font-size: 12px;
    }
  }
  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}

.dark-layout {
  .b-overlay-wrap ::v-deep .b-overlay {
    // border: 10px solid red;
    .bg-white {
      background-color: $theme-dark-body-bg !important;
    }
  }
}
</style>
