import { render, staticRenderFns } from "./BCardNormal.vue?vue&type=template&id=327dc0b8&scoped=true&"
import script from "./BCardNormal.vue?vue&type=script&lang=js&"
export * from "./BCardNormal.vue?vue&type=script&lang=js&"
import style0 from "./BCardNormal.vue?vue&type=style&index=0&id=327dc0b8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "327dc0b8",
  null
  
)

export default component.exports